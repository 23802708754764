export const dictionaryRoutes: { [key: string]: string } = {
  Grupossub1: "Sub-Grupo (Nível 1)",
  Grupossub2: "Sub-Grupo (Nível 2)",
  Localizacoes: "Localizações",
  Servicos: "Serviços",
  Seratividades: "Atividades de Serviços",
  Pagtoforma: "Forma de Pagamento",
  Pagtoprazo: "Prazo de Pagamento",
  Natoperacao: "Natureza da Operação",
  Usuarios: "Usuários",
  Municipios: "Municípios",
  Paises: "Países",
  Relatorios: "Relatórios",
  Cfop: "CFOP",
  Config: "Configuração",
  Plano: "Plano Contratado",
  usuariosgrupospermissoes: "Grupo de Permissões",
  relatorio: "Relatório",
  depositos: "Depósitos",
  Emitentes: "Emitentes",
  Vendedores: "Vendedores",
  Funcionarios: "Funcionários",
  Transportadoras: "Transportadoras",
  Clientes: "Clientes",
  fornecedores: "Fornecedores",
  unidades: "Unidades",
  fabricantes: "Fabricantes",
  grupos: "Grupos",
  gruposub1: "Sub-Grupos (Nível 1)",
  gruposub2: "Sub-Grupos (Nível 2)",
  Produtos: "Produtos",
  ncm: "NCM",
  vendas: "Vendas > Varejo",
  NFe: "NF-e",
  Nfce: "NFC-e",
  Nfse: "NFS-e",
  clientesveiculos: "Veículos",
  vendasveiculos: "Vendas > OS Veículos",
  compras: "Compras",
  bancos: "Bancos",
  maquinascartao: "Máquinas de Cartão",
  caixas: "Caixas",
  contasreceber: "Contas de Receber",
  contaspagar: "Contas de Pagar",
  juncao: "Junção",
  consultarboletos: "Consultar Boletos API",
  remessacnab: "Gerar Arquivo de Remessa CNAB",
  retornocnab: "Importar Arquivo de Retorno CNAB",
};
